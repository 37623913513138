import styles from "./VideoModal.module.css";
import Modal from "react-modal";
import sprite from "../../../../icons.svg";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectUserData } from "../../../../../redux/userData/selectors";
import { getUserData } from "../../../../../redux/userData/operations";

export default function VideoModal({ onClose, isOpen }) {
  const videosAggre = [
    {
      id: "BaevaAggre",
      link: "https://www.youtube.com/embed/zX4zB4Uog-c?si=T8ucdnBEin2ZbthM",
    },
    {
      id: "PrudnikovaAggre",
      link: "https://www.youtube.com/embed/k5GdDO0AeAw?si=AKZqBJ3i9qzN5QrZ",
    },
    {
      id: "MatushevskaAggre",
      link: "https://www.youtube.com/embed/Op9OZWNXpJg?si=htF05wkzyJJJM4VW",
    },
    {
      id: "MarchekAggre",
      link: "https://www.youtube.com/embed/cHPX0VOT3KA?si=UvKZ61Mo58mpJKY3",
    },
    {
      id: "VatrichAggre",
      link: "https://www.youtube.com/embed/TjVaUJIhzI4?si=qbbg7bC19gYZB4xz",
    },
    {
      id: "ZimovinAggre",
      link: "https://www.youtube.com/embed/1BzgscpgfnQ?si=f4IAHCs0KqnVQRvS",
    },
    {
      id: "LevchenkoAggre",
      link: "https://www.youtube.com/embed/Q1DZeN1spg4?si=bXsmHshoch29-Ow8",
    },
    {
      id: "PavluchokAggre",
      link: "https://www.youtube.com/embed/XC7jsJ9aY08?si=68AoNQuw9NUrS3yG",
    },
    {
      id: "DiveevaAggre",
      link: "https://www.youtube.com/embed/oSArVAqsbps?si=OUsKGNE9bGOIboYt",
    },
    {
      id: "NazarenkoAggre",
      link: "https://www.youtube.com/embed/4e2s3Sh2pMM?si=XQBowcgqGtS9mWJh",
    },
    {
      id: "IshkovaAggre",
      link: "https://www.youtube.com/embed/tOmA03r8jGQ?si=DdmUBnM8ZBveDjjL",
    },
    {
      id: "AdamovaAggre",
      link: "https://www.youtube.com/embed/BP1aWJ6WlVk?si=QYJbJoFNYH9awZoQ",
    },
  ];

const videosFlirt = [
  {
    id: "MatveevaFlirt",
    link: "https://www.youtube.com/embed/1cm9Ev6g9o0?si=lv_lhGzakQZcuLqn",
  },
  {
    id: "PavluchokFlirt",
    link: "https://www.youtube.com/embed/lI6yaxAOMLc?si=IDtZi_SedDHDAhKL",
  },
  {
    id: "MarchekFlirt",
    link: "https://www.youtube.com/embed/XYeu7QekNdI?si=25wf4uKkSn6AjeRu",
  },
  {
    id: "HayvoronskaFlirt",
    link: "https://www.youtube.com/embed/FolXQYBo-lo?si=1bvQuLuwY2Jub_85",
  },
  {
    id: "BaevaFlirt",
    link: "https://www.youtube.com/embed/yliY47ZYOJE?si=4mqQMlNXbMuSQ1Nb",
  },
  {
    id: "SolohubFlirt",
    link: "https://www.youtube.com/embed/yG3G0jmozaU?si=0sCXpToQ9ScihVpx",
  },
  {
    id: "AdamovaFlirt",
    link: "https://www.youtube.com/embed/KEokPYHi88I?si=n5h1doXnnKhTT0RH",
  },
  {
    id: "PrudnikovaFlirt",
    link: "https://www.youtube.com/embed/ffXuNWtbKdE?si=bpxtetIb2REvmLQy",
  },
  {
    id: "NesvitFlirt",
    link: "https://www.youtube.com/embed/MwczHoDs1EU?si=r7_TS4yxTLmqmQYP",
  },
  {
    id: "MatushevskaFlirt",
    link: "https://www.youtube.com/embed/bBo8E6BNDi0?si=HyBNLByvuS-2n1EE",
  },
  {
    id: "KozachkovaFlirt",
    link: "https://www.youtube.com/embed/zwQovTXxrf0?si=R8G0CQoAQYVehnut",
  },
];

  const userData = useSelector(selectUserData);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserData()); // Вызываем экшен для получения данных пользователя
  }, [dispatch]);

  const showVideosFromAggre =
    userData?.conferences?.agreeConference?.paidVideos;
  const showVideosFromFlirt =
    userData?.conferences?.flirtConference?.paidVideos;

  // Функция для поиска видео по ID
  const findVideoLink = (id, videosArray) => {
    const video = videosArray.find((video) => video.id === id);
    return video ? video.link : "";
  };

  // Составляем массивы с найденными видео для вывода
  const videoLinksToDisplay = [];

  if (showVideosFromAggre) {
    showVideosFromAggre.forEach((videoId) => {
      const link = findVideoLink(videoId, videosAggre);
      if (link) {
        videoLinksToDisplay.push(link);
      }
    });
  }

  if (showVideosFromFlirt) {
    showVideosFromFlirt.forEach((videoId) => {
      const link = findVideoLink(videoId, videosFlirt);
      if (link) {
        videoLinksToDisplay.push(link);
      }
    });
  }

  return (
    <Modal
      isOpen={isOpen}
      overlayClassName={styles.videoModal}
      className={styles.contentVideoModal}
      closeTimeoutMS={400}
      onRequestClose={onClose}
      ariaHideApp={false}
    >
      <svg
        className={styles.closeVideoModal}
        onClick={onClose}
        width={24}
        height={24}
      >
        <use xlinkHref={`${sprite}#icon-close`}></use>
      </svg>
      <ul className={styles.videoList}>
        {videoLinksToDisplay.length > 0 ? (
          videoLinksToDisplay.map((link, index) => (
            <li key={index}>
              <iframe
                className={styles.videoIframe}
                src={link}
                title={`YouTube video player ${index + 1}`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </li>
          ))
        ) : (
          <p className={styles.noVideosText}>
            У вас поки немає придбаних відеозаписів для відтворення.
          </p>
        )}
      </ul>
    </Modal>
  );
}
