import styles from './Header.module.css'
import sprite from '../../icons.svg'
import { Link } from 'react-router-dom';
import { BurgerMenuModalRetrite } from './Modals';
import { useState } from 'react';

export default function HeaderR() {
    const [modalPrizeIsOpen, setModalPrizeOpen] = useState(false)

    return (
      <header className={styles.headerRetrite}>
        <Link to="/">
          <svg className={styles.mainLogoIMGRetrite} width={130} height={40}>
            <use xlinkHref={`${sprite}#icon-womenPlace`}></use>
          </svg>
        </Link>
        <button className={styles.contactUsBtnRetrite}>
          Звʼязатись з нами
        </button>
        <svg
          className={styles.headerBurgerMenuBtnRetrite}
          width={50}
          height={40}
            onClick={() => setModalPrizeOpen(true)}
        >
          <use xlinkHref={`${sprite}#icon-burger-menu`}></use>
        </svg>
        <BurgerMenuModalRetrite
          isOpen={modalPrizeIsOpen}
          onClose={() => setModalPrizeOpen(false)}
        ></BurgerMenuModalRetrite>
      </header>
    );
}