import styles from './InstagramA.module.css'
import { Swiper, SwiperSlide } from "swiper/react";
import AnastasiiaInstagram1 from './instagramAnastasia1.webp'
import AnastasiiaInstagram2 from './instagramAnastasia2.webp'
import AnastasiiaInstagram3 from './instagramAnastasia3.webp'
import "swiper/css/effect-cube";
import "swiper/css/pagination";

import { EffectCreative, Pagination } from "swiper/modules";


export default function InstagramA() {
   
    return (
      <section className={styles.sectionInstargamA}>
        <div className="container">
          <h2 className={styles.titleInstagramA}>instagram</h2>
          <Swiper
            grabCursor={true}
            effect={"creative"}
            creativeEffect={{
              prev: {
                shadow: true,
                translate: ["-120%", 0, -500],
              },
              next: {
                shadow: true,
                translate: ["120%", 0, -500],
              },
            }}
            pagination={true}
            modules={[EffectCreative, Pagination]}
            className={styles.mySwiperInstagramA}
          >
            <SwiperSlide>
              <img
                src={AnastasiiaInstagram2}
                alt="Anastasiia"
                width={360}
                height={400}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={AnastasiiaInstagram3}
                alt="Anastasiia"
                width={360}
                height={400}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={AnastasiiaInstagram1}
                alt="Anastasiia"
                width={360}
                height={400}
              />
            </SwiperSlide>
          </Swiper>
          <ul className={styles.mySwiperInstagramADesc}>
            <li><img
            className={styles.AnastasiiaInstagram}
                src={AnastasiiaInstagram2}
                alt="Anastasiia"
                width={360}
                height={400}
              /></li>
            <li><img
            className={styles.AnastasiiaInstagram}
                src={AnastasiiaInstagram3}
                alt="Anastasiia"
                width={360}
                height={400}
              /></li>
            <li><img
            className={styles.AnastasiiaInstagram}
                src={AnastasiiaInstagram1}
                alt="Anastasiia"
                width={360}
                height={400}
              /></li>
          </ul>
          <a className={styles.linkToInstagramA} href="#" target="_blank">
            ПЕРЕЙТИ
          </a>
        </div>
      </section>
    );
}