import styles from "./OurVillas.module.css";
import "swiper/css";
import "swiper/css/pagination";
import sprite from "../../icons.svg";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { imagesData } from "./imagesData.js";
import { conveniencesData } from "./conveniencesData.js";

export const OurVillas = () => {
  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <h2 className={styles.sectionTitle}>наші вілли</h2>
        <p className={styles.villaTitle}>Вілла №1</p>
        <p className={styles.villaDescription}>
          Велика квартира 190 м2 з окремим входом та приватним садом 400 м2
          <br /> в буржуазному будинку, розташованому{" "}
          <span className={styles.villaHighlighredText}>
            в центрі міста Канн
          </span>
          . 1 хвилина ходьби
          <br /> від вулиці Антіб і в 5 хвилинах ходьби{" "}
          <br className={styles.onlyOnMobile} /> від Круазетт та її пляжів.{" "}
          <br className={styles.onlyOnDekstop} /> 3 спальні{" "}
          <br className={styles.onlyOnMobile} /> з власними ванними кімнатами.
        </p>
        <div className={styles.swiperAndConveniencesWrapper}>
          <div className={styles.swiperContainer}>
            <button className={`${styles.prevButton} ${styles.onlyOnMobile}`}>
              <svg
                className={styles.prevButtonIcon}
                width={25}
                height={25}
                fill="white"
              >
                <use xlinkHref={`${sprite}#icon-doubleLeftArrow`}></use>
              </svg>
            </button>
            <Swiper
              modules={[Navigation, Pagination]}
              navigation={{
                prevEl: `.${styles.prevButton}`,
                nextEl: `.${styles.nextButton}`,
              }}
              pagination={{ clickable: true }}
              className={styles.swiper}
              slidesPerView={1}
              spaceBetween={40}
            >
              {imagesData.map((image, index) => (
                <SwiperSlide key={index} className={styles.swiperSlide}>
                  <img src={image} alt={`Slide ${index}`} />
                </SwiperSlide>
              ))}
            </Swiper>
            <button className={`${styles.nextButton} ${styles.onlyOnMobile}`}>
              <svg
                className={styles.nextButtonIcon}
                width={25}
                height={25}
                fill="white"
              >
                <use xlinkHref={`${sprite}#icon-doubleRightArrow`}></use>
              </svg>
            </button>
          </div>
          <div className={styles.conveniencesContainer}>
            <p className={styles.conveniencesTitle}>Якi тут зручностi?</p>
            <ul className={styles.conveniencesList}>
              {conveniencesData.map((text, index) => {
                return (
                  <li className={styles.conveniencesListItem} key={index}>
                    <span className={styles.conveniencesTextIcon}> ~</span>
                    <p className={styles.conveniencesText}>{text}</p>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <p className={styles.additionalInfo}>
          *інформація про другу віллу буде додана на сайт згодом
        </p>
      </div>
    </section>
  );
};
