import { useEffect, useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./MainPage.module.css";
import sprite from "./icons.svg";
import "swiper/css";
import "swiper/css/effect-creative";
import "swiper/css/navigation";
import IMGForConference from "../assets/windowScreenConference.webp";
import IMGForRetrite from "../assets/windowScreenRetrite.webp";
import { EffectCreative, Navigation } from "swiper/modules";
import { Link } from "react-router-dom";

export default function MainPage() {
  const [sectionVisible, setSectionVisible] = useState(false);
  const [containerVisible, setContainerVisible] = useState(false);
  const [isCurtainOpen, setIsCurtainOpen] = useState(false);
  const [showMainPage, setShowMainPage] = useState(true);
  const [showChoiceSection, setShowChoiceSection] = useState(false);
  const swiperRef = useRef(null);

  useEffect(() => {
    const sectionTimer = setTimeout(() => {
      setSectionVisible(true);
    }, 50);

    const containerTimer = setTimeout(() => {
      setContainerVisible(true);
    }, 1000);

    return () => {
      clearTimeout(sectionTimer);
      clearTimeout(containerTimer);
    };
  }, []);

  useEffect(() => {
    if (isCurtainOpen) {
      const curtainHideTimer = setTimeout(() => {}, 2000);

      const curtainCloseTimer = setTimeout(() => {
        setIsCurtainOpen(false);
      }, 2000);

      const swapSection = setTimeout(() => {
        setShowMainPage(false);
        setShowChoiceSection(true);
      }, 2000);

      return () => {
        clearTimeout(curtainHideTimer);
        clearTimeout(curtainCloseTimer);
        clearTimeout(swapSection);
      };
    }
  }, [isCurtainOpen]);

  return (
    <>
      {showMainPage && (
        <section
          className={`${styles.sectionMainPage} ${
            sectionVisible ? styles.visible : ""
          }`}
        >
          <div
            className={`${styles.fakeContainer} ${
              containerVisible ? styles.visible : ""
            }`}
          >
            <p className={styles.textMainPageWelcome}>ВАС ВІТАЮТЬ</p>
            <svg
              className={styles.mainPageWomenPlaceLogo}
              width={620}
              height={420}
            >
              <use xlinkHref={`${sprite}#icon-womenPlace`}></use>
            </svg>
            <button
              className={styles.transparentBtnMainPage}
              onClick={() => setIsCurtainOpen(true)}
            >
              розпочати
            </button>
            <p className={styles.textMainPageAbout}>
              Проєкт «ЖІНКИ» представляє собою трилогію зустрічей для жінок, яка
              надає унікальну можливість пройти через ініціацію у різних
              форматах. Це дозволяє кожній жінці знайти новий досвід,
              переосмислити своє становище та зрозуміти себе з різних сторін.
            </p>
            <ul className={styles.wrapperSocialIconMainPage}>
              <li>
                <a
                  target="_blank"
                  href="https://www.instagram.com/women.psyconference/"
                >
                  <svg
                    className={styles.mainPageWomenSocialIcon}
                    width={25}
                    height={25}
                  >
                    <use xlinkHref={`${sprite}#icon-instagram`}></use>
                  </svg>
                </a>
              </li>
              <li>
                <a target="_blank" href="https://t.me/women_psyconference">
                  <svg
                    className={styles.mainPageWomenSocialIcon}
                    width={25}
                    height={25}
                  >
                    <use xlinkHref={`${sprite}#icon-telegram`}></use>
                  </svg>
                </a>
              </li>
              <li>
                <a target="_blank" href="#">
                  <svg
                    className={styles.mainPageWomenSocialIcon}
                    width={25}
                    height={25}
                  >
                    <use xlinkHref={`${sprite}#icon-facebook`}></use>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </section>
      )}
      <div
        className={`${styles.wrapperCurtainWindow} ${
          isCurtainOpen ? styles.visibleWrapperCurtanWindow : ""
        }`}
      >
        <p className={styles.textGradientOurProject}>наші проєкти</p>
      </div>
      {showChoiceSection && (
        <section
          className={`${styles.sectionChoiseAProject} ${
            showChoiceSection ? styles.sectionChoiseAProjectDisplayBlock : ""
          }`}
        >
          <div className={styles.mySwiperMainPagesNavigation}>
            <div
              className={styles.swiperButtonMainPrev}
              onClick={() => swiperRef.current.swiper.slidePrev()}
            >
              <svg
                className={styles.swiperButtonMainPrevBorder}
                width={40}
                height={40}
                fill="white"
              >
                <use xlinkHref={`${sprite}#icon-arrow-left-main`}></use>
              </svg>
            </div>
            <div
              className={styles.swiperButtonMainNext}
              onClick={() => swiperRef.current.swiper.slideNext()}
            >
              <svg
                className={styles.swiperButtonMainNextBorder}
                width={40}
                height={40}
                fill="white"
              >
                <use xlinkHref={`${sprite}#icon-arrow-right-main`}></use>
              </svg>
            </div>
          </div>
          <Swiper
            ref={swiperRef}
            grabCursor={true}
            effect={"creative"}
            creativeEffect={{
              prev: {
                shadow: true,
                translate: ["-20%", 0, -1],
              },
              next: {
                shadow: true,
                translate: ["100%", 0, 0],
              },
            }}
            speed={1500}
            navigation={{
              prevEl: `.${styles.swiperButtonMainPrev}`,
              nextEl: `.${styles.swiperButtonMainNext}`,
            }}
            modules={[EffectCreative, Navigation]}
            allowTouchMove={false}
            className={styles.mySwiperMainPageChoiseAProject}
          >
            <SwiperSlide className={styles.swiperSlideCustomBackConference}>
              <div className={styles.wrapperTitleMainConferenceSlide}>
                <h2 className={styles.titleMainConferenceSlide}>конференція</h2>
                <svg
                  className={styles.womenPlaceConferencePage}
                  width={550}
                  height={120}
                >
                  <use xlinkHref={`${sprite}#icon-womenPlace`}></use>
                </svg>
              </div>
              <img
                className={`${styles.IMGForConference} ${styles.IMGForConferenceF}`}
                src={IMGForConference}
                alt="Conference"
                width={640}
                height={300}
              />
              <Link to={"/conference"}>
                <button className={styles.transparentBtnMainPage}>
                  перейти на сайт
                </button>
              </Link>
              <p className={styles.textMainPageAbout}>
                Конференція «ЖІНКИ» - 2-денний онлайн лекторій про кожен з
                етапів розвитку жінок. Це зіткнення з новим досвідом, що
                призводить до переосмислення ситуації та свого становища з
                різних сторін: теорія, практика, зустріч.
              </p>
              <ul className={styles.wrapperSocialIconMainPage}>
                <li>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/women.psyconference/"
                  >
                    <svg
                      className={styles.mainPageWomenSocialIcon}
                      width={25}
                      height={25}
                    >
                      <use xlinkHref={`${sprite}#icon-instagram`}></use>
                    </svg>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://t.me/women_psyconference">
                    <svg
                      className={styles.mainPageWomenSocialIcon}
                      width={25}
                      height={25}
                    >
                      <use xlinkHref={`${sprite}#icon-telegram`}></use>
                    </svg>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="#">
                    <svg
                      className={styles.mainPageWomenSocialIcon}
                      width={25}
                      height={25}
                    >
                      <use xlinkHref={`${sprite}#icon-facebook`}></use>
                    </svg>
                  </a>
                </li>
              </ul>
            </SwiperSlide>
            <SwiperSlide className={styles.swiperSlideCustomBackRetrite}>
              <div className={styles.wrapperTitleMainRetriteSlide}>
                <h2 className={styles.titleMainRetriteSlide}>ретрит</h2>
                <svg
                  className={styles.womenPlaceRetritePage}
                  width={550}
                  height={120}
                >
                  <use xlinkHref={`${sprite}#icon-womenPlace`}></use>
                </svg>
              </div>
              <img
                className={styles.IMGForConference}
                src={IMGForRetrite}
                alt="Retrite"
                width={640}
                height={300}
              />
              <Link to={"/retrite"}>
                <button className={styles.transparentBtnMainPage}>
                  перейти на сайт
                </button>
              </Link>
              <p className={styles.textMainPageAbout}>
                Тілесно-психологічний ретрит для жінок “В тілі” - це можливість
                дослідити свої внутрішні та зовнішні аспекти, розкрити нові
                грані, зустрітися зі своїми страхами та переосмислити свою
                жіночу сутність. Це подорож до себе, що підкреслює прийняття
                свого тіла, про відновлення та, що найголовніше, про
                сестринство.
              </p>
              <ul className={styles.wrapperSocialIconMainPage}>
                <li>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/women.psyconference/"
                  >
                    <svg
                      className={styles.mainPageWomenSocialIcon}
                      width={25}
                      height={25}
                    >
                      <use xlinkHref={`${sprite}#icon-instagram`}></use>
                    </svg>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://t.me/women_psyconference">
                    <svg
                      className={styles.mainPageWomenSocialIcon}
                      width={25}
                      height={25}
                    >
                      <use xlinkHref={`${sprite}#icon-telegram`}></use>
                    </svg>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="#">
                    <svg
                      className={styles.mainPageWomenSocialIcon}
                      width={25}
                      height={25}
                    >
                      <use xlinkHref={`${sprite}#icon-facebook`}></use>
                    </svg>
                  </a>
                </li>
              </ul>
            </SwiperSlide>
          </Swiper>
        </section>
      )}
    </>
  );
}
