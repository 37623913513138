import "./Modules.css";
import Modal from "react-modal";
import sprite from "../../../icons.svg";
// import { useNavigate } from "react-router-dom";
import axios from "axios";
// import ReactPixel from "react-facebook-pixel";

// eslint-disable-next-line react/prop-types
export const BurgerMenuModal = ({ isOpen, onClose, children }) => {
  return (
    <Modal
      isOpen={isOpen}
      overlayClassName={"modalBurgerMenu"}
      className={"modalContentBurgerMenu"}
      closeTimeoutMS={400}
      onRequestClose={() => onClose()}
      ariaHideApp={false}
    >
      <svg
        className="closeBurgerMenuBtn"
        width={32}
        height={32}
        onClick={onClose}
      >
        <use xlinkHref={`${sprite}#icon-close`}></use>
      </svg>
      {children}
    </Modal>
  );
};

// eslint-disable-next-line react/prop-types
export const MoreQuestions = ({ isOpen, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      overlayClassName={"modalMoreQuestion"}
      className={"modalContentMoreQuestion"}
      closeTimeoutMS={400}
      onRequestClose={() => onClose}
      ariaHideApp={false}
    >
      <svg
        className="closeModalQuestions"
        width={32}
        height={32}
        onClick={onClose}
      >
        <use xlinkHref={`${sprite}#icon-close`}></use>
      </svg>
      <h2 className="titleMoreQuestion">
        За додатковими питання зверніться до нас за цими контактами:
      </h2>
      <a href="tel:+380936576711" className="modalContactUsLinks">
        + 38 (093) 657 67 11
      </a>
      <a href="tel:+48667994337" className="modalContactUsLinks">
        +48 667 994 337
      </a>
      <a href="mailto:women@psyconference.org" className="modalContactUsLinks">
        women@psyconference.org
      </a>
      <ul className="wrapperModalSocialLinks">
        <li>
          <a
            href="https://www.instagram.com/women.psyconference/"
            target="_blank"
          >
            <svg className="modalSocialIcons" width={40} height={40}>
              <use xlinkHref={`${sprite}#icon-instagram`}></use>
            </svg>
          </a>
        </li>
        <li>
          <a href="https://t.me/women_psyconference" target="_blank">
            <svg className="modalSocialIcons" width={40} height={40}>
              <use xlinkHref={`${sprite}#icon-telegram`}></use>
            </svg>
          </a>
        </li>
      </ul>
    </Modal>
  );
};

// eslint-disable-next-line react/prop-types
export const JoinToZoom = ({ isOpen, onClose }) => {
  const zoomURL =
    "https://us06web.zoom.us/j/87080882648?pwd=uvUSMNAMBNKMPFJ4dy0jGPjL2SIoUr.1"; // Ваш URL Zoom

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = e.target.elements.email.value.toLowerCase();
    try {
      const res = await axios.patch(
        `https://www.women.place/api/users/${email}`
      );
      console.log("Email scheduled for deletion");

      const deleteAt = res.data.user.deleteAt;

      console.log("deleteAt:", deleteAt);

      if (deleteAt) {
        console.log("proverka");

        // Проверяем просто на наличие значения, null и undefined будут пропущены
        const currentDate = Date.now();
        const deleteAtDate = new Date(deleteAt).getTime();
        console.log(currentDate);
        console.log(deleteAtDate);

        // Проверяем, не истек ли срок доступа
        const isUserHaveAccess = deleteAtDate > currentDate;
        console.log("isUserHaveAccess", isUserHaveAccess);

        if (!isUserHaveAccess) {
          console.log("Время доступа к конференции истекло");
          alert("Час доступу до конференції закінчився!");
          return;
        }
      }

      window.location.href = zoomURL; // Переход в Zoom
    } catch (error) {
      console.log(error);
      alert("Пошта недійсна!"); // Выводим сообщение об ошибке пользователю
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      overlayClassName={"modalJoinToZoom"}
      className={"modalContentJoinToZoom"}
      closeTimeoutMS={400}
      onRequestClose={onClose}
      ariaHideApp={false}
    >
      <svg className="closeModalJoinToZoom" onClick={onClose}>
        <use xlinkHref={`${sprite}#icon-close`}></use>
      </svg>
      <h2 className="titleModalJoinToZoom">
        Вітаємо! <br />
        <span className="spanModalJoinToZoom">
          надайте ваші данні для авторизації на конференцію
        </span>
      </h2>
      <form className="formJoinToZoom" onSubmit={handleSubmit}>
        <input
          className="inputJoinToZoom"
          type="text"
          placeholder="Email"
          name="email"
          required
        />
        <p className="warningJoinToZoom">
          Важливо написати пошту за якою ви реєструвались, інакше ви не
          потрапите на конференцію
        </p>
        <button className="joinToZoomButton" type="submit">
          Перейти в Zoom
        </button>
      </form>
    </Modal>
  );
};
