import styles from "./LogIn.module.css";
import Modal from "react-modal";
import { useState, useEffect } from "react";
import sprite from "../../icons.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getGoogleOAuthUrl,
  logIn,
  refreshUser,
  register,
} from "../../../redux/auth/operations";
import { selectAuthError } from "../../../redux/auth/selectors";

export default function LogIn({ isOpen, onClose, mode }) {
  const [isAgreementChecked, setIsAgreementChecked] = useState(false);
  const [hasAuthPassed, setHasAuthPassed] = useState(false);
  const [currentMode, setCurrentMode] = useState(mode);
  const authError = useSelector(selectAuthError);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const closeModal = () => {
    setHasAuthPassed(false);
    onClose();
  };
  useEffect(() => {
    // Синхронизировать currentMode с mode при изменении mode
    setCurrentMode(mode);
  }, [mode]);
  // const isLoggedIn = useSelector(selectIsLoggedIn); // СТАТУС ЛОГИНА
  const switchMode = (newMode) => {
    setCurrentMode(newMode);
    setHasAuthPassed(false);
  };

  const handleAgreementChange = (event) => {
    setIsAgreementChecked(event.target.checked);
  };

  useEffect(() => {
    if (currentMode === "login") {
      setIsAgreementChecked(false);
    }
  }, [currentMode]);

  useEffect(() => {
    if (hasAuthPassed && authError === false) {
      console.log("closing");
      closeModal();
    }
  }, [authError, hasAuthPassed, closeModal]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(refreshUser());
  }, [dispatch]);

  useEffect(() => {
    console.log("hasAuthPassed: ", hasAuthPassed);
  }, [hasAuthPassed]);

  const handleRegister = async (e) => {
    e.preventDefault();
    const formElems = e.target.elements;
    const credentials = {
      firstName: formElems.userNameLogIn.value.trim(),
      lastName: formElems.userSurnameLogIn.value.trim(),
      email: formElems.userEmailLogIn.value.trim(),
      password: formElems.userPasswordLogIn.value.trim(),
    };
    await dispatch(register(credentials));
    console.log("Register button work");
    setHasAuthPassed(true);
  };

  const handleLogIn = async (e) => {
    e.preventDefault();
    const formElems = e.target.elements;
    const credentials = {
      email: formElems.userEmailLogIn.value.trim(),
      password: formElems.userPasswordLogIn.value.trim(),
    };
    await dispatch(logIn(credentials));
    console.log("Login button work");
    setHasAuthPassed(true);
  };

  const handleGoogleLogIn = async () => {
    console.log("click");

    const oAuthUrl = await dispatch(getGoogleOAuthUrl()).unwrap();
    console.log(oAuthUrl);

    window.location.href = oAuthUrl; // Перенаправление на Google OAuth
  };

  return (
    <Modal
      isOpen={isOpen}
      overlayClassName={styles.modalLogIn}
      className={styles.modalContentLogIn}
      closeTimeoutMS={400}
      onRequestClose={closeModal}
      ariaHideApp={false}
    >
      <svg
        className={styles.closeModalLogIn}
        onClick={closeModal}
        width={24}
        height={24}
      >
        <use xlinkHref={`${sprite}#icon-close`}></use>
      </svg>
      <h2 className={styles.titleLogInModal}>
        {currentMode === "login" ? "Вхід" : "Реєстрація"}
      </h2>
      <button
        className={styles.googleAuthBtn}
        id="googleAuthBtn"
        onClick={handleGoogleLogIn}
      >
        <svg
          className={styles.iconGoogleBtn}
          onClick={() => {}}
          width={24}
          height={24}
        >
          <use xlinkHref={`${sprite}#icon-google`}></use>
        </svg>
        {currentMode === "login" ? "увійти через google" : "google реєстрація"}
      </button>
      <p className={styles.justText}>або через пошту</p>
      <form
        className={styles.logInForm}
        id="logInForm"
        onSubmit={
          currentMode === "registration"
            ? (e) => handleRegister(e)
            : (e) => handleLogIn(e)
        }
      >
        {/* Поля для имени и фамилии видны только в режиме регистрации */}
        {currentMode === "registration" && (
          <>
            <div className={styles.formGroupLogIn}>
              <label htmlFor="userNameLogIn" className={styles.logInLabel}>
                <input
                  className={styles.inputUserEmailLogIn}
                  type="text"
                  id="userNameLogIn"
                  name="userNameLogIn"
                  required={currentMode === "registration"}
                />
                <span className={styles.spanInputPlaceholder}>Імʼя</span>
              </label>
            </div>
            <div className={styles.formGroupLogIn}>
              <label htmlFor="userSurnameLogIn" className={styles.logInLabel}>
                <input
                  className={styles.inputUserEmailLogIn}
                  type="text"
                  id="userSurnameLogIn"
                  name="userSurnameLogIn"
                  required={currentMode === "registration"}
                />
                <span className={styles.spanInputPlaceholder}>Прізвище</span>
              </label>
            </div>
          </>
        )}
        <div className={styles.formGroupLogIn}>
          <label htmlFor="userEmailLogIn" className={styles.logInLabel}>
            <input
              className={styles.inputUserEmailLogIn}
              type="email"
              id="userEmailLogIn"
              name="userEmailLogIn"
              required
            />
            <span className={styles.spanInputPlaceholder}>Email</span>
          </label>
        </div>
        <div className={styles.formGroupLogIn}>
          <label htmlFor="userPasswordLogIn" className={styles.logInLabel}>
            <input
              className={styles.inputUserEmailLogIn}
              type="password"
              id="userPasswordLogIn"
              name="userPasswordLogIn"
              required
            />
            <span className={styles.spanInputPlaceholder}>Пароль</span>
          </label>
        </div>
        {/* Чекбокс и соглашение только для регистрации */}
        {currentMode === "registration" && (
          <label className={styles.markAgreePolicy}>
            <input
              type="checkbox"
              id="agreeRegistration"
              checked={isAgreementChecked}
              onChange={handleAgreementChange}
              required
            />
            Реєструючись, ви погоджуєтесь з <span>“Умовами використання”</span>{" "}
            та <span>“Політикою конфіденційності”</span> автороссилку на ваш
            email, і даєте згоду на обробку персональних даних.
          </label>
        )}
        {authError && hasAuthPassed && (
          <p className={styles.errorText}>
            {currentMode === "registration"
              ? "Емейл вже зайнято! Спробуйте інший варіант."
              : "Неправильний логін або пароль!"}
          </p>
        )}
        <ul className={styles.wrapperCheckPasswordBtns}>
          <li>
            <button
              className={styles.LogInBtn}
              id="logInBtn"
              type="submit"
              disabled={currentMode === "registration" && !isAgreementChecked}
            >
              {currentMode === "login" ? "Увійти" : "Зареєструватись"}
            </button>
          </li>
          {currentMode === "login" && ( // Кнопка "Забули пароль?" только для входа
            <li>
              <button className={styles.forgotPassBtn}>Забули пароль?</button>
            </li>
          )}
        </ul>
      </form>

      <p className={styles.justText}>
        {currentMode === "login" ? "Ще немає профілю?" : "Вже є профіль?"}
      </p>
      <button
        className={styles.RegistrationBtn}
        onClick={() =>
          switchMode(currentMode === "login" ? "registration" : "login")
        } // Переключение режимов по нажатию
      >
        {currentMode === "login" ? "до реєстрації" : "до входу"}
      </button>
    </Modal>
  );
}
