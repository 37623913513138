import { useState } from "react";
import styles from "./Questions.module.css";
import sprite from "../../icons.svg";

export default function Questions() {
  // Состояние для хранения активного вопроса
  const [activeQuestion, setActiveQuestion] = useState(null);

  // Функция для обработки клика на вопрос
  const handleQuestionClick = (index) => {
    setActiveQuestion(index === activeQuestion ? null : index);
  };

  return (
    <section className={styles.sectionQuestionsRetrite}>
      <div className="container">
        <h2 className={styles.titleQuestions}>питання</h2>
        <ul className={styles.wrapperQuestionsAnswer}>
          {questionsData.map((question, index) => (
            <li key={index}>
              <p
                className={styles.numberOfQuestions}
                onClick={() => handleQuestionClick(index)}
              >
                {question.title}
              </p>
              <div
                className={`${styles.hiddenAnswerText} ${
                  activeQuestion === index ? styles.hiddenAnswerTextShow : ""
                }`}
              >
                {question.content}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}

// Данные для вопросов и ответов
const questionsData = [
  {
    title: "ЩО ВХОДИТЬ У ВАРТІСТЬ?",
    content: (
      <>
        <ul className={styles.wrapperAnswerList}>
          <li>проживання*</li>
          <li>три дводенки з Анастасією</li>
          <li>арт- та тілесна терапія</li>
          <li>ранкова йога/фітнес</li>
          <li>екскурсія</li>
          <li>подорож на яхті та вечеря на острові</li>
          <li>лекція від топ відомих експертів краси та здоровʼя</li>
          <li>випускний</li>
          <li>фоторепортаж зустрічей</li>
        </ul>
        <p className={styles.answerWithSpiral}>
          <svg className={styles.spiralRetrite} width={20} height={20}>
            <use xlinkHref={`${sprite}#icon-spiralRetrite`}></use>
          </svg>
          з проживанням у 3+ зірки готелі або апартаментах при двомісному
          розміщенні та розважальною програмою (розміщення самостійно - на 500€
          менше від тарифу)
        </p>
        <p className={styles.answerWithSpiral}>
          <svg className={styles.spiralRetrite} width={20} height={20}>
            <use xlinkHref={`${sprite}#icon-spiralRetrite`}></use>
          </svg>
          ціна проживання на віллі, де будуть проходити дводенки з Анастасією,
          прораховуються індивідуально в залежності від обраної кімнати та
          розміщення
        </p>
      </>
    ),
  },
  {
    title: "ЩО НЕ ВХОДИТЬ У ВАРТІСТЬ?",
    content: (
      <ul className={styles.wrapperAnswerList}>
        <li>переліт</li>
        <li>трансфер</li>
        <li>харчування (окрім двох вечерь, які входять у вартість)</li>
        <li>алкоголь</li>
      </ul>
    ),
  },
  {
    title: "ВАРІАНТИ ОПЛАТИ",
    content: (
      <>
        <p className={styles.answerWithSpiral}>
          <svg className={styles.spiralRetrite} width={20} height={20}>
            <use xlinkHref={`${sprite}#icon-spiralRetrite`}></use>
          </svg>
          Місце бронюється при передоплаті 1000 євро. Залишок суми сплачується
          кожного наступного першого числа місяця
        </p>
      </>
    ),
  },
  {
    title: "ЩО ВЗЯТИ З СОБОЮ?",
    content: (
      <ul className={styles.wrapperAnswerList}>
        <li>кросівки</li>
        <li>одяг для занять йогою</li>
        <li>купальники</li>
        <li>плаття вільного крою</li>
      </ul>
    ),
  },
];
