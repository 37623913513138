import styles from "./ProfileModal.module.css";
import sprite from "../../icons.svg";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Modal from "react-modal";

import MyConference from "../../UsersProfiles/MyConference/MyConference";
import Support from "../../UsersProfiles/Support/Support";
import Settings from "../../UsersProfiles/Settings/Settings";
import Notification from "../../UsersProfiles/Notifications/Notifications";
import MyProfile from "../../UsersProfiles/MyProfile/MyProfile";
import OurConference from "../../UsersProfiles/OurConference/OurConference";
import { logOut } from "../../../redux/auth/operations";
import { useDispatch } from "react-redux";

export default function ProfileModal({ isOpen, onClose, onProfileSection }) {
  const [selectedSection, setSelectedSection] = useState("Profile");
  const dispatch = useDispatch();
  // обновление секций
  useEffect(() => {
    if (typeof onProfileSection === "function") {
      onProfileSection(renderSection(selectedSection));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSection]);

  const renderSection = (section) => {
    switch (section) {
      case "Profile":
        return <MyProfile />;
      case "MyConference":
        return <MyConference />;
      case "Support":
        return <Support />;
      case "Settings":
        return <Settings />;
      case "Notification":
        return <Notification />;
      case "OurConference":
        return <OurConference />;
      default:
        return <MyProfile />;
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      overlayClassName={styles.modalProfile}
      className={styles.modalContentProfile}
      closeTimeoutMS={400}
      onRequestClose={onClose}
      ariaHideApp={false}
    >
      <ul className={styles.wrapperProfileNavigation}>
        <li
          className={`${styles.navigation} ${
            selectedSection === "Profile" ? styles.navigationActive : ""
          } ${styles.navigationProfile}`}
          onClick={() => {
            setSelectedSection("Profile");
            onClose();
          }}
        >
          <Link to="/profile">
            <svg className={styles.navigationSVG} width={24} height={24}>
              <use xlinkHref={`${sprite}#icon-user`}></use>
            </svg>
            Мій профіль
          </Link>
        </li>
        <li
          className={`${styles.navigation} ${
            selectedSection === "MyConference" ? styles.navigationActive : ""
          }`}
          onClick={() => {
            setSelectedSection("MyConference");
            onClose();
          }}
        >
          <Link to="/profile">
            <svg className={styles.navigationSVG} width={24} height={24}>
              <use xlinkHref={`${sprite}#icon-playProfile`}></use>
            </svg>
            Мої записи
          </Link>
        </li>
        <li
          className={`${styles.navigation} ${
            selectedSection === "OurConference" ? styles.navigationActive : ""
          }`}
          onClick={() => {
            setSelectedSection("OurConference");
            onClose();
          }}
        >
          <Link to="/profile">
            <svg className={styles.navigationSVG} width={24} height={24}>
              <use xlinkHref={`${sprite}#icon-client`}></use>
            </svg>
            Наші конференції
          </Link>
        </li>
        <li
          className={`${styles.navigation} ${
            selectedSection === "Notification" ? styles.navigationActive : ""
          }`}
          onClick={() => {
            setSelectedSection("Notification");
            onClose();
          }}
        >
          <Link to="/profile">
            <svg className={styles.navigationSVG} width={24} height={24}>
              <use xlinkHref={`${sprite}#icon-notificationsProfile`}></use>
            </svg>
            Сповіщення
          </Link>
        </li>
        <li
          className={`${styles.navigation} ${
            selectedSection === "Settings" ? styles.navigationActive : ""
          }`}
          onClick={() => {
            setSelectedSection("Settings");
            onClose();
          }}
        >
          <Link to="/profile">
            <svg className={styles.navigationSVG} width={24} height={24}>
              <use xlinkHref={`${sprite}#icon-settingsProfile`}></use>
            </svg>
            Налаштування
          </Link>
        </li>
        <li
          className={`${styles.navigation} ${
            selectedSection === "Support" ? styles.navigationActive : ""
          }`}
          onClick={() => {
            setSelectedSection("Support");
            onClose();
          }}
        >
          <Link to="/profile">
            <svg className={styles.navigationSVG} width={24} height={24}>
              <use xlinkHref={`${sprite}#icon-supportProfile`}></use>
            </svg>
            Підтримка
          </Link>
        </li>
      </ul>
      <button
        className={styles.logOutBtn}
        onClick={() => {
          console.log("LOGOUT");

          dispatch(logOut());
          onClose();
        }}
      >
        <svg className={styles.logOutSVG} width={24} height={24}>
          <use xlinkHref={`${sprite}#icon-logOutSVG`}></use>
        </svg>
        Вийти
      </button>
    </Modal>
  );
}
