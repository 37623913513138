import { BookingModal } from "../BookingModal/BookingModal";
import styles from "./Galarey.module.css";
import imgInText1 from "./imgInText1.webp";
import imgInText2 from "./imgInText2.webp";

export default function Galarey() {
  return (
    <section className={styles.sectionGalarey}>
      {/* <h2 className={styles.titleGalarey}>галерея</h2> */}
      <ul className={styles.galareyFirstCollection}></ul>
      <ul className={styles.galareySecondCollection}></ul>
      <ul className={styles.galareyTrithCollection}></ul>
      <div className="container">
        <h3 className={styles.titleForOpenForm}>
          забронюй місце зараз{" "}
          <img
            className={styles.imgText}
            src={imgInText1}
            alt="img"
            width={110}
            height={50}
          />{" "}
          і потрапляй на зустріч{" "}
          <img
            className={styles.imgText}
            src={imgInText2}
            alt="img"
            width={110}
            height={50}
          />
          за найнижчу ціну!
        </h3>
        <BookingModal />
      </div>
    </section>
  );
}
