import sprite from "../../icons.svg";
import styles from "./MyConference.module.css";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectUserData } from "../../../redux/userData/selectors";
import { getUserData } from "../../../redux/userData/operations";
import VideoModal from "./ModalsForVideo/VideoModal/VideoModal";
import VideoForChoise from "./ModalsForVideo/VideoForChoise/VideoForChoise";

export default function MyConference() {
  const [videoModalIsOpen, setVideoModalIsOpen] = useState(false);
  const [videoForChoiseIsOpen, setVideoForChoiseIsOpen] = useState(false);
  const [conferenceName, setConferenceName] = useState("");

  const userData = useSelector(selectUserData);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserData()); // Вызываем экшен для получения данных пользователя
  }, [dispatch]);

  // Проверяем на статус оплаты конференции
  const paidStatusOnConferenceAggre =
    userData?.conferences?.agreeConference?.paidConferenceTariffs || [];
  const paidStatusOnConferenceFlirt =
    userData?.conferences?.flirtConference?.paidConferenceTariffs || [];
  const paidStatusOnConferenceProhibition =
    userData?.conferences?.prohibitionConference?.paidConferenceTariffs || [];

  // Проверяем массив с видео
  const myAggreVideo = userData?.conferences?.agreeConference?.paidVideos || [];
  const myFlirtVideo = userData?.conferences?.flirtConference?.paidVideos || [];
  const myProhibitionVideo =
    userData?.conferences?.prohibitionConference?.paidVideos || [];

  // Проверяем наличие тарифа с видео или купленных видео
  const havePaidStatusAggre =
    paidStatusOnConferenceAggre.includes("parmsTwo") ||
    paidStatusOnConferenceAggre.includes("parmsThree");
  const havePaidStatusFlirt =
    paidStatusOnConferenceFlirt.includes("parmsTwo") ||
    paidStatusOnConferenceFlirt.includes("parmsThree");
  const havePaidStatusProhibition =
    paidStatusOnConferenceProhibition.includes("parmsTwo") ||
    paidStatusOnConferenceProhibition.includes("parmsThree");

  // Проверяем отсутствие оплаченных записей для вывода сообщения
  const noPaidConferences =
    !havePaidStatusAggre &&
    !havePaidStatusFlirt &&
    !havePaidStatusProhibition &&
    myAggreVideo.length === 0 &&
    myFlirtVideo.length === 0 &&
    myProhibitionVideo.length === 0;

  const handleOpenVideoForChoise = (name) => {
    setConferenceName(name);
    setVideoForChoiseIsOpen(true);
  };

  return (
    <section className={styles.myConferenceSection}>
      <div className="container">
        <h2 className={styles.titleMyConferenceVideo}>
          <svg className={styles.MyVideoSVG} width={20} height={20}>
            <use xlinkHref={`${sprite}#icon-play`}></use>
          </svg>
          Мої записи
        </h2>
        {noPaidConferences && (
          <p className={styles.textWithOutPaidVideo}>
            У вас поки немає придбаних записів
          </p>
        )}
        <ul className={styles.wrapperAllConference}>
          {/* ПРО АГРЕСІЮ */}
          {(havePaidStatusAggre || myAggreVideo.length > 0) && (
            <li>
              <h3 className={styles.titleThisConference}>
                ПРО АГРЕСІЮ: жінок, до жінок, біля жінок
              </h3>
              <p className={styles.dateThisConference}>
                30 червня - 1 липня 2024
              </p>
              <button
                className={styles.thisConferenceBtn}
                onClick={() => {
                  if (myAggreVideo.length > 0) {
                    setVideoModalIsOpen(true);
                  } else if (havePaidStatusAggre) {
                    handleOpenVideoForChoise("agreeConference");
                  }
                }}
              >
                {myAggreVideo.length > 0 ? "Переглянути" : "Обрати"}
              </button>
            </li>
          )}

          {/* ФЛІРТ */}
          {(havePaidStatusFlirt || myFlirtVideo.length > 0) && (
            <li>
              <h3 className={styles.titleThisConference}>
                флірт, кокетство, любовна гра
              </h3>
              <p className={styles.dateThisConference}>10 - 11 вересня 2024</p>
              <button
                className={styles.thisConferenceBtn}
                onClick={() => {
                  if (myFlirtVideo.length > 0) {
                    setVideoModalIsOpen(true);
                  } else if (havePaidStatusFlirt) {
                    handleOpenVideoForChoise("flirtConference");
                  }
                }}
              >
                {myFlirtVideo.length > 0 ? "Переглянути" : "Обрати"}
              </button>
            </li>
          )}

          {/* ЗАБОРОНИ */}
          {(havePaidStatusProhibition || myProhibitionVideo.length > 0) && (
            <li>
              <h3 className={styles.titleThisConference}>
                КОРДОНИ ТА МЕЖІ <br />
                Заборони та їх порушення
              </h3>
              <p className={styles.dateThisConference}>10 - 11 вересня 2024</p>
              <button
                className={styles.thisConferenceBtn}
                onClick={() => {
                  if (myProhibitionVideo.length > 0) {
                    setVideoModalIsOpen(true);
                  } else if (havePaidStatusProhibition) {
                    handleOpenVideoForChoise("prohibitionConference");
                  }
                }}
              >
                {myProhibitionVideo.length > 0 ? "Переглянути" : "Обрати"}
              </button>
            </li>
          )}
        </ul>
      </div>
      <VideoModal
        isOpen={videoModalIsOpen}
        onClose={() => setVideoModalIsOpen(false)}
      />
      <VideoForChoise
        isOpen={videoForChoiseIsOpen}
        onClose={() => setVideoForChoiseIsOpen(false)}
        conferenceName={conferenceName} // Передаем название конференции
      />
    </section>
  );
}
