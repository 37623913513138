import { useState } from "react";
import styles from "./Questions.module.css";

export default function Questions() {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAnswer = (index) => {
    setOpenIndex(openIndex === index ? null : index); 
  };

  const faqs = [
    {
      question: "Скільки часу триватиме один день конференції?",
      answer:
        "Наші конференційні дні насичені і триватимуть з 10:00 до 20:15 за київським часом. В програмі передбачена обідня перерва з 14:30 до 15:30, щоб ви могли відпочити та відновити сили.",
    },
    {
      question: "На якій платформі будуть проходити виступи спікерів?",
      answer:
        "Всі виступи відбуватимуться на платформі Zoom, яка забезпечує зручний доступ і якісний зв’язок для всіх учасників.",
    },
    {
      question: "Скільки часу я матиму доступ до запису виступів?",
      answer:
        "Ви матимете цілий рік, щоб переглядати записи виступів і повертатися до них, коли вам зручно.",
    },
    {
      question: "Чи можна буде придбати запис виступів після конференції?",
      answer:
        "Так, навіть якщо ви пропустите конференцію, ви зможете придбати записи виступів і отримати всі матеріали після події.",
    },
    {
      question: "Чи можу я здійснити оплату частинами?",
      answer:
        "Наразі такої можливості немає, але ми працюємо над тим, щоб у майбутньому додати опцію оплати частинами.",
    },
    {
      question: "Я оплатила(-в), що далі?",
      answer:
        "Після вашої оплати ви отримаєте підтвердження на вашу електронну адресу. У цьому листі буде інформація про вашу оплату і можливості, які ви отримуєте. Також очікуйте наступних листів з розкладом конференції, детальними інструкціями та нагадуваннями, щоб ви не пропустили жодної важливої деталі. Ми прагнемо зробити вашу участь у конференції максимально комфортною та корисною. Якщо у вас виникнуть додаткові запитання, не соромтеся звертатися до нас!",
    },
  ];

  return (
    <section className={styles.sectionQuestions} id="questionsSection">
      <div className="container">
        <h2 className={styles.titleQuestions}>/F&Q</h2>
        <ul className={styles.wrapperFAQs}>
          {faqs.map((faq, index) => (
            <li key={index} className={styles.borderLiAnswer}>
              <h3
                className={`${styles.titleQuestionList} ${
                  openIndex === index ? styles.activeQuestion : ""
                }`}
                onClick={() => toggleAnswer(index)}
              >
                {faq.question}
                <span
                  className={`${styles.openAnswerPlus} ${
                    openIndex === index ? styles.rotatePlus : ""
                  }`}
                ></span>
              </h3>
              <p
                className={`${styles.textAnswer} ${
                  openIndex === index ? styles.showAnswer : styles.hideAnswer
                }`}
              >
                {faq.answer}
              </p>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}
