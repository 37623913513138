import styles from "./Comment.module.css";

export const Comment = () => {
  return (
    <section className={styles.section}>
      <div className={styles.commentContainer}>
        <p className={styles.commentText}>
          Я знаю, що це буде по-різному, але 100% асертивно. Бо тепло, чесно,
          весело, глибоко, ніжно, смачно, цікаво, загадково, сексуально,
          чуттєво, <br className={styles.dekstopOnly} /> близько та обʼємно - це
          те що Я люблю і те, як Я вмію…
        </p>
      </div>
    </section>
  );
};
