import styles from "./Profile.module.css";
import { Link } from "react-router-dom";
import sprite from "../icons.svg";
import LogIn from "../Modals/LogIn/LogIn";
import ProfileModal from "../Modals/ProfileModal/ProfileModal";
import { selectIsLoggedIn } from "../../redux/auth/selectors";
import { useSelector } from "react-redux";
import { useState } from "react";
import FooterProfile from "./FooterProfile/FotterProfile";

export default function ProfilePage() {
  const [modalLogInIsOpen, setModalLogIn] = useState(false);
  const [modalProfileIsOpen, setModalProfile] = useState(false);
  const [profileSection, setProfileSection] = useState(null);

  const [modalMode] = useState("login");
  const isLoggedIn = useSelector(selectIsLoggedIn);

  return (
    <div className={styles.profileWhiteBackground}>
      <header className={styles.headerProfile}>
        <div className="container">
          <Link to="/conference">
            <svg className={styles.mainLogoIMG} width={236} height={70}>
              <use xlinkHref={`${sprite}#icon-womenPlace`}></use>
            </svg>
          </Link>
          <button
            className={styles.LogInBtn}
            onClick={() =>
              isLoggedIn ? setModalProfile(true) : setModalLogIn(true)
            }
          >
            <svg className={styles.logInBtnSVG} width={24} height={24}>
              <use xlinkHref={`${sprite}#icon-user`}></use>
            </svg>
            <span className={styles.LogInBtnText}>
              {isLoggedIn ? "мій профіль" : "вхід"}
            </span>
          </button>
          <svg
            className={styles.burgerMenuProfile}
            width={50}
            height={36}
            onClick={() => {}}
          >
            <use xlinkHref={`${sprite}#icon-burger-menu`}></use>
          </svg>
        </div>
      </header>
      <div className={styles.profileContent} id="renderSection">
        {profileSection}
      </div>
      <FooterProfile />
      <LogIn
        isOpen={modalLogInIsOpen}
        onClose={() => setModalLogIn(false)}
        mode={modalMode}
      ></LogIn>
      <ProfileModal
        isOpen={modalProfileIsOpen}
        onClose={() => setModalProfile(false)}
        mode={modalMode}
        onProfileSection={setProfileSection}
      ></ProfileModal>
    </div>
  );
}
