import styles from './Rozklad.module.css'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import shedule1 from './shedule1.webp'
import shedule2 from './shedule2.webp'
import shedule3 from './shedule3.webp'
import shedule4 from './shedule4.webp'
import shedule5 from './shedule5.webp'
import shedule6 from './shedule6.webp'
import shedule7 from './shedule7.webp'
import { Pagination } from 'swiper/modules';

export default function Rozklad() {

    const shedulesObj = [
      {
        img: shedule1,
        date: "06.10",
        info: "заїзд",
      },
      {
        img: shedule2,
        date: "07.10-08.10",
        info: "І дводенка",
      },
      {
        img: shedule3,
        date: "09.10",
        info: " вільний день",
      },
      {
        img: shedule4,
        date: "10.10-11.10",
        info: "II дводенка",
      },
      {
        img: shedule5,
        date: "12.10",
        info: "вільний день",
      },
      {
        img: shedule6,
        date: "13.10-14.10",
        info: "III дводенка",
      },
      {
        img: shedule7,
        date: "15.10",
        info: "виїзд",
      },
    ];

    return (
      <section className={styles.sectionRozklad}>
        <div className="container">
          <h2 className={styles.titleRozklad}>розклад ІНТЕНСИВУ</h2>
          <Swiper
            slidesPerView={3}
            spaceBetween={40}
            pagination={{ clickable: true }}
                    modules={[Pagination]}
                    autoHeight={true}
          >
            {shedulesObj.map((card, index) => (
              <SwiperSlide key={index} className={styles.swiperSlideBackIMG}>
                <p className={styles.titleDateCard}>{card.date}</p>
                <p className={styles.textInfoCard}>{card.info}</p>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    );
}