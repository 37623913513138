import style from "./FlirtSpeakers.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import AnastasiiaMatIMG from "./AnastasiiaMatIMG.webp";
import SvitlanaMarIMG from "./SvitlanaMarIMG.webp";
import BaevaIMG from "./BaevaIMG.webp";
import PrudnikovaIMG from "./PrudnikovaIMG.webp";
import NesvitIMG from "./NesvitIMG.webp";
import KozachkovaIMG from "./KozachkovaIMG.webp";
import SolohubIMG from "./SolohubIMG.webp";
import AdamovaIMG from "./AdamovaIMG.webp";
import HayvoronskaIMG from "./HayvoronskaIMG.webp";
import SozanovskaIMG from "./SozanovskaIMG.webp";
import PavluchokIMG from "./PavluchokIMG.webp";
import sprite from "../../../icons.svg";
import "swiper/css/scrollbar";
import { Scrollbar, Pagination, Navigation, Virtual } from "swiper/modules";
import "swiper/css/pagination";
// import { useState } from "react";

export default function FlirtSpeakers() {
  const speakersJSON = [
    {
      speakerIMG: AnastasiiaMatIMG,
      speakerIMGCSS: "",
      speakerName: "Анастасія-Олімпія Матюшевська",
      speakerInstagram:
        "https://www.instagram.com/anastasia.olimpia.matushevska?igsh=MWZlbGxvd2wzOHEw",
      width: "445",
      height: "445",
      speakerTema: "Бажання дражнити, щоб вас піддразнювали",
      speakerAbout: [
        "Гештальт-терапевт НАГТУ",
        "Супервізор",
        "Акредитований член EAGT",
        "Спеціаліст по роботі з сексуальною тематикою",
        "Засновниця проектів психологічно-емоційного розвитку «ЖІНКИ»: конференції, ретрит",
      ],
    },
    {
      speakerIMG: BaevaIMG,
      speakerIMGCSS: "speakerIMGBaeva",
      speakerName: "Олена Баєва",
      speakerInstagram:
        "https://www.instagram.com/fannie_flagg?igsh=aWlpbDJjZDhpdjl1",
      width: "445",
      height: "445",
      speakerTema:
        "Театр жіночого життя: гра як форма розвитку, атмосфера гри, жіночі ігри",
      speakerAbout: [
        "Гештальт-терапевт",
        "Супервізор",
        "Провідний тренер НАГТУ",
        'Ведуча навчальних програм, спеціалізації "Гештальт-терапія з дітьми та сім`єю", "Теорії розвитку та вікові кризи в Гештальт-терапії"',
      ],
    },
    {
      speakerIMG: SvitlanaMarIMG,
      speakerIMGCSS: "speakerIMGMarchek",
      speakerName: "Світлана Марчек",
      speakerInstagram:
        "https://www.instagram.com/svitlana_marcek?igsh=aTNvNTh5NHM0eGxl",
      width: "445",
      height: "445",
      speakerTema: "Флірт як втеча від близькості",
      speakerAbout: [
        "Гештальт-терапевт",
        "Травматерапевт",
        "Сертифікований арт-терапевт",
        "Спеціаліст з роботи з шоковими травмами, втратами, ПТСР та КПТСР, а також по роботі з ранньою травмою",
      ],
    },
    {
      speakerIMG: PrudnikovaIMG,
      speakerIMGCSS: "speakerIMGPrudnikova",
      speakerName: "Вікторія Пруднікова",
      speakerInstagram:
        "https://www.instagram.com/prudnikovavictoria?igsh=eXZuOTZ1aWNjMm5v",
      width: "445",
      height: "445",
      speakerTema: "Аудит життя",
      speakerAbout: [
        "Експерт з ментальної генетики",
        "Консультант",
        "Соціолог",
        "Блогер",
        "Автор методики «PPF Past Present Future» та «АУДИТИ ЖИТТЯ»",
        "Автор проекту Наставництва «Жінка. Гроші. Призначення»",
      ],
    },
    {
      speakerIMG: NesvitIMG,
      speakerIMGCSS: "speakerIMGNesvit",
      speakerName: "Костянтин Несвіт",
      speakerInstagram:
        "https://www.instagram.com/knesvit?igsh=MXVobzZ0a29jYzB1OQ==",
      width: "385",
      height: "385",
      speakerTema: "Флірт як практична навичка. Елементи флірту та їх розвиток",
      speakerAbout: [
        "Чоловічий психоло",
        "10 років практики",
        "автор книги і засновник «Чоловічого психологічного клубу»",
        "Працює з клієнтами рівня ТОП 40 Forbes",
        "Спеціалізується на темі стосунків та їх відсутності.",
      ],
    },
    {
      speakerIMG: KozachkovaIMG,
      speakerIMGCSS: "speakerIMGKozachkova",
      speakerName: "Юлія Козачкова",
      speakerInstagram:
        "https://www.instagram.com/kozachkova_yuliia?igsh=M3czeG0zMjZ3Mzlq",
      width: "385",
      height: "385",
      speakerTema: "Жіночність та гроші",
      speakerAbout: [
        "Психолог-практик",
        "Коуч",
        "Майстер НЛП",
        "Фасилітатор ТоР Owner додатку Teya та Teya Shop",
        "Co-creator «Kozachkova club»",
        "Автор серії книг «Мистецтво бути в ЦЕЙ:ЧАС»",
        "Co-owner маркетингової агенції «Спокусливий бренд»",
      ],
    },
    {
      speakerIMG: SolohubIMG,
      speakerIMGCSS: "speakerIMGSolohub",
      speakerName: "Ольга Сологуб",
      speakerInstagram:
        "https://www.instagram.com/olga.sologub?igsh=ZGcyNGk3M2dtNmg=",
      width: "425",
      height: "425",
      speakerTema: "Флірт як стиль життя",
      speakerAbout: [
        "Психолог",
        "Сексолог",
        "Супервізор",
        "Груповий терапевт",
        "Тренер інституту MIGIS",
        "Автор програми « СЕКСОЛОГІЯ і Гештальт терапія»",
        "Автор програми «Сексуальне виховання дітей»",
      ],
    },
    {
      speakerIMG: AdamovaIMG,
      speakerIMGCSS: "speakerIMGAdamova",
      speakerName: "Лєна Адамова",
      speakerInstagram:
        "https://www.instagram.com/adamova_elena__?igsh=MTI5MnZ2M2hsOHZwZQ==",
      width: "425",
      height: "425",
      speakerTema: "Мова спілкування сексуальності",
      speakerAbout: [
        "Кандидат юридичних наук",
        "Психолог",
        "Психосоматолог",
        "Експерт-психолог",
        "Коуч-трансформатор рубрики «Жити легко з Адамовою» на ТРК Україна",
      ],
    },
    {
      speakerIMG: PavluchokIMG,
      speakerIMGCSS: "speakerIMGPavluchok",
      speakerName: "Наталія Павлючок",
      speakerInstagram: "https://www.instagram.com/natalia.pavluchok/",
      width: "425",
      height: "425",
      speakerTema: "Ідентичність та флірт",
      speakerAbout: [
        "Підприємниця",
        "Амбасадорка здорових сімейних відносин",
        "Співвласниця харчового виробництва",
        "Власниця бутік-готелю",
      ],
    },
    {
      speakerIMG: HayvoronskaIMG,
      speakerIMGCSS: "speakerIMGHayvoronska",
      speakerName: "Юлія Гайворонская",
      speakerInstagram:
        "https://www.instagram.com/yuliya_gayvoronskaya?igsh=MXJmOXVmcGtpcTlveA==",
      width: "380",
      height: "380",
      speakerTema: "Як навчитися любити флірт та себе в ньому",
      speakerAbout: [
        "Секс коуч",
        "Займається психологічним консультуванням в області сексології",
        "Тренер з сексуальної освіти",
        "Власниця магазину для дорослих gayvoronskaya.com",
      ],
    },
    {
      speakerIMG: SozanovskaIMG,
      speakerIMGCSS: "speakerIMGSozanovska",
      speakerName: "Леся Матвєєва-Созановська",
      speakerInstagram:
        "https://www.instagram.com/lesiamatveyeva?igsh=MXFudDZ0djc3MnB2Ng==",
      width: "460",
      height: "460",
      speakerTema: "Як підвищити самооцінку та стати впевненою у собі.",
      speakerAbout: [
        "Практичний психолог та психоаналітик",
        "Консультант з особистісного розвитку",
        "Тренер з впевненості у собі",
        "Член Національної Психологічної Асоціації",
      ],
    },
  ];

  return (
    <section className={style.speakersSection} id="speakersSection">
      <div className="container">
        <h2 className={style.titleSpeakers}>/спікери конференції</h2>
      </div>
      <ul className={style.wrapperAboutWhatSpeakers}>
        <li className={style.wrapperAboutWhatSpeakersLi}>
          <p className={style.textHowMutchSpeakers}>11</p>
          <p className={style.textAnswerHowMutchSpeakers}>
            поглядів на одну тему
          </p>
        </li>
        <li className={style.wrapperAboutWhatSpeakersLi}>
          <p className={style.textAnswerHowMutchSpeakersTwo}>
            Спікери конференцій — сертифіковані експерти, які із різних боків
            розриють тему флірту та нададуть інструменти для пізнання та
            усвідомлення жінки.
          </p>
        </li>
      </ul>
      <div className="container">
        <div
          className={`${style.mySwiperSpeakersNavigation} swiper-navigation`}
        >
          <div className={style.swiperButtonPrev}>
            <svg
              onClick={(e) => e.preventDefault()}
              className=""
              width={60}
              height={60}
              fill="white"
            >
              <use xlinkHref={`${sprite}#arrow-right`}></use>
            </svg>
          </div>
          <div className={style.swiperButtonNext}>
            <svg
              onClick={(e) => e.preventDefault()}
              className=""
              width={60}
              height={60}
              fill="white"
            >
              <use xlinkHref={`${sprite}#arrow-left`}></use>
            </svg>
          </div>
        </div>
        <Swiper
          slidesPerView={1}
          spaceBetween={40}
          // autoHeight={true}
          scrollbar={{
            draggable: true,
          }}
          pagination={{
            type: "fraction",
          }}
          navigation={{
            prevEl: `.${style.swiperButtonPrev}`,
            nextEl: `.${style.swiperButtonNext}`,
          }}
          modules={[Scrollbar, Pagination, Navigation, Virtual]}
          className={style.mySwiperSpeakers}
        >
          {speakersJSON.map((speaker, index) => (
            <SwiperSlide key={index} virtualIndex={index}>
              <div className={style.wrapperSpeakerInfo}>
                <div className={style.wrapperSpeakerPhoto}>
                  <img
                    className={`${style.speakerIMG} ${speaker.speakerIMGCSS}`}
                    src={speaker.speakerIMG}
                    alt={speaker.speakerName}
                    width={speaker.width}
                    height={speaker.height}
                  />
                  <span className={style.ellipsesBehindSpeakers}></span>
                </div>
                <div className={style.wrapperSpeakerName}>
                  <p className={style.titleSpeakerName}>
                    {speaker.speakerName}
                  </p>
                  <a
                    alt="Instagram"
                    aria-label="Перейти на Instagram"
                    target="_blank"
                    href={speaker.speakerInstagram}
                    className={style.linkToInstagramSpeaker}
                  >
                    <svg className={style.instagramSVG} width={60} height={60}>
                      <use xlinkHref={`${sprite}#icon-instagram`}></use>
                    </svg>
                  </a>
                </div>
                <Swiper
                  slidesPerView={1}
                  spaceBetween={40}
                  pagination={{ clickable: true }}
                  modules={[Pagination]}
                  autoHeight={true}
                  className={style.mySwiperSpeakersMobile}
                >
                  <SwiperSlide>
                    <h3 className={style.titleThisInfo}>Тема виступу:</h3>
                    <p className={style.textThisInfo}>{speaker.speakerTema}</p>
                  </SwiperSlide>
                  <SwiperSlide>
                    <h3 className={style.titleThisInfo}>Про спікера:</h3>
                    <ul className={style.wrapperTextThisInfo}>
                      {speaker.speakerAbout.map((about, idx) => (
                        <li className={style.textThisInfoLi} key={idx}>
                          {about}
                        </li>
                      ))}
                    </ul>
                  </SwiperSlide>
                  <SwiperSlide>
                    <h3 className={style.titleThisInfo}>Дата виступу:</h3>
                    <p className={style.textThisInfo}>10 - 11 вересня 2024</p>
                  </SwiperSlide>
                </Swiper>
              </div>
              <ul className={style.wrapperConferenceThisSpeakerInfo}>
                <li className={style.wrapperConferenceThisSpeakerInfoLi}>
                  <h3 className={style.titleThisInfo}>тема виступу:</h3>
                  <p className={style.textThisInfo}>{speaker.speakerTema}</p>
                </li>
                <li className={style.wrapperConferenceThisSpeakerInfoLi}>
                  <h3 className={style.titleThisInfo}>про спікера:</h3>
                  <ul className={style.wrapperTextThisInfo}>
                    {speaker.speakerAbout.map((about, idx) => (
                      <li className={style.textThisInfoLi} key={idx}>
                        {about}
                      </li>
                    ))}
                  </ul>
                </li>
                <li className={style.wrapperConferenceThisSpeakerInfoLi}>
                  <h3 className={style.titleThisInfo}>дата виступу:</h3>
                  <p className={style.textThisInfo}>10 - 11 вересня 2024</p>
                </li>
              </ul>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
}
